.stats {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.stats p {
  color: var(--text-color-tertiary);
  line-height: 1.5em;
  text-align: center;
}

.stats p span,
.boxes .container p span {
  /* font-weight: 500; */
  color: var(--text-color-primary);
  transform: translateY(1px);
}

.boxes {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin: 20px 0;
  position: relative;
}

.boxes .container {
  background-color: var(--bg-color-7);
  border-radius: 6px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: filter 300ms;
}

.boxes .container p {
  font-size: 1.3em;
  margin: -8px 0 0;
}

.boxes .container span {
  color: var(--text-color-tertiary);
  font-size: .9em;
  font-weight: 500;
}

.boxes .container p {
  display: flex;
  align-items: center;
  gap: 5px;
}

.boxes .container svg.icon {
  float: right;
  height: 28px;
  color: var(--theme-color-contrast);
}

.boxes[data-loading="true"] .container {
  filter: blur(5px);
}

.boxes .loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, .5); */
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms;
}

.boxes[data-loading="true"] .loading {
  opacity: 1;
  pointer-events: all;
}

.boxes .loading div {
  height: 40px;
  width: 40px;
  border: 4px solid var(--bg-color-4);
  border-top: 4px solid var(--theme-color);
  margin: 0 0 15px;
}