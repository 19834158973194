.input {
  margin: 10px 0;
  background-color: var(--bg-color-2);
  outline: 0;
  border: 0;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  color: var(--text-color-primary);
}

button.bottom {
  margin-top: auto;
}

.badge {
  background: #0894E2;
  color: #001b2a;
  font-weight: 600;
  padding: 0 5px;
  border-radius: 5px;
  line-height: 21px;
  font-size: 12px;
  display: inline-block;
  margin: 8px 0;
}