/* dropdown */
.dropdown {
  background-color: var(--bg-color-4);
  /* background-color: #20202C; */
  /* background-color: #29293863; */
  position: absolute;
  bottom: 10px;
  right: 10px;
  /* transform: translateY(100%); */
  /* left: 0; */
  /* width: 123px; */
  padding: 10px 0;
  border-radius: 5px;
  --line-height: 16px;
  z-index: 5;
  opacity: 0;
  transform: translateY(calc(100% - 10px));
  transition: opacity 300ms, transform 300ms;
  pointer-events: none;
  box-shadow: 0px 1px 5px rgb(0 0 0 / 18%);
}

@keyframes fadeInDropdown {
  0% {
    transform: translateY(calc(100% - 10px));
    opacity: 0;
  }
  100% {
    transform: translateY(100%);
    opacity: 1;
  }
}

.dropdown[data-visible="true"] {
  animation: fadeInDropdown 300ms;
  transform: translateY(100%);
  opacity: 1;
  pointer-events: all;
}

.dropdown li {
  padding: 8px 15px;
  float: left;
  width: calc(100% - 30px);
  transition: background 300ms;
  font-size: 12px;
}

.dropdown li:hover {
  cursor: pointer;
  background-color: var(--bg-color-0);
}

.dropdown li svg {
  position: initial !important;
  opacity: 1 !important;
  float: left;
  height: var(--line-height) !important;
  /* width: var(--line-height) !important; */
  color: var(--text-color-chat);
}

.dropdown li span {
  float: left;
  height: var(--line-height);
  line-height: var(--line-height) !important;
  color: var(--text-color-primary) !important;
  display: block;
  margin-left: 7px;
  font-weight: 500;
  width: calc(100% - 16px - 7px);
  transform: translateY(2px);
}
/* end dropdown */