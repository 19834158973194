.preview {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  word-wrap: break-all;
}

.preview h5,
.preview p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
}

.preview h5 {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color-chat);
}

.preview p {
  margin: 5px 0 15px;
  white-space: break-spaces;
}