.edit {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.edit .input {
  background-color: var(--bg-color-2);
  width: 50%;
  margin: 0 25%;
  float: left;
  border-radius: 52px;
}

.edit .desc {
  font-size: 14px;
  color: var(--text-color-chat);
  margin: 0 0 30px;
  text-align: center;
}

.edit .input input {
  outline: 0;
  border: 0;
  background: transparent;
  color: var(--text-color-primary);
  height: 34px;
  float: left;
  width: calc(100% - 38px);
}

.edit .input svg {
  height: 16px;
  margin: 9px;
  float: left;
}

.action {
  width: 80%;
  float: left;
  margin: 20px 10%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.action button {
  outline: 0;
  border: 0;
  line-height: 28px;
  background-color: var(--bg-color-2);
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}

.action button[data-selected="true"] {
  /* color: var(--theme-color); */
  background: var(--bg-color-5);
}

.action button:hover {
  cursor: pointer;
}

.action button:nth-child(1) {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.action button:nth-last-child(1) {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.edit button {
  margin-top: auto;
}