.edit {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.edit .desc {
  font-size: 14px;
  color: var(--text-color-chat);
  margin: 0 0 30px;
  text-align: center;
  line-height: 1.5em;
}

.edit .desc span {
  color: var(--text-color-primary);
}

.btns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  margin: 15px 0;
}

.btns button {
  outline: 0;
  border: 2px solid transparent;
  /* border: 2px solid var(--bg-color-5); */
  background-color: var(--bg-color-0);
  color: var(--text-color-chat);
  border-radius: 6px;
  font-weight: 600;
  padding: 8px 0;
}

.btns button:hover,
.btns button[data-active="true"] {
  cursor: pointer;
  background-color: var(--bg-color-4);
}

.edit textarea {
  width: calc(100% - 20px);
  height: 70px;
  resize: none;
  margin: 15px 0 0;
  padding: 10px;
  border-radius: 6px;
  background-color: var(--bg-color-0);
  color: var(--text-color-chat);
}


.edit button {
  margin-top: auto;
}