.sidebar {
  width: 88px;
  padding: 12px;
  height: calc(100vh - 24px);
  background-color: var(--bg-color-3);
  float: left;
  box-shadow: inset -1px 0px 0px rgba(150, 156, 178, 0.14);
  display: flex;
  flex-direction: column;
  user-select: none;
}

.social {
  margin: auto 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.social a {
  margin: 20px 0 0;
}

.social svg {
  width: 30px;
  color: var(--text-color-secondary);
  transition: color 150ms;
}

.social a:hover svg {
  color: var(--theme-color);
  /* color: var(--text-color-primary); */
}

.logo {
  width: 100%;
  height: 88px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 150ms;
}

.logo:hover {
  opacity: .65;
}

.logo img {
  width: 70%;
}

.games {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
}

.games a {
  width: 88px;
  height: 88px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  transition: background 150ms;
}

.games a svg {
  width: 32px;
  filter: grayscale(1);
}

/* .games a svg.fillTheme {
  display: none;
} */

.games a svg.fillTheme *,
.games a svg.strokeTheme path {
  fill: var(--theme-color);
}

.games a svg.strokeTheme path:nth-child(1) {
  fill: none;
}

.games a p {
  margin: 8px 0;
  font-size: 13px;
  font-weight: 600;
  color: var(--text-color-secondary);
}

.games a p.desc {
  color: var(--text-color-primary);
  font-size: 12px;
  margin: 0;
}

.games a p.desc img,
.games a p.desc span {
  float: left;
}

.games a p.desc img {
  height: 14px;
  transform: translateY(-1px);
}

.games a p.desc span {
  margin-left: 5px;
  display: block;
  line-height: 14px;
}

.games a.active svg {
  filter: none;
}

.games a.active {
  background: linear-gradient(180deg, rgba(131, 42, 245, 0) 0%, var(--theme-color) 120%), #262A30;
  /* background: linear-gradient(180deg, rgba(131, 42, 245, 0) 0%, #822af5b2 100%), #262A30; */
  /* background: radial-gradient(165.91% 100% at 50% 0%, rgba(131, 42, 245, 0) 23.48%, rgba(131, 42, 245, 0.27) 56.69%, rgba(131, 42, 245, 0.4) 78.39%, rgba(131, 42, 245, 0.62) 89.91%, #832AF5 100%), var(--bg-color-2); */
}

.games a:hover {
  background-color: var(--bg-color-2);
}

.games a:hover svg {
  filter: none;
}



@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  /* .link svg {
    transform: translateY(-1px);
  } */
}