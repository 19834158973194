.input {
  margin: 10px 0;
  background-color: var(--bg-color-2);
  outline: 0;
  border: 0;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  color: var(--text-color-primary);
}

.bottom {
  margin-top: auto;
}

.bottom button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0 0;
}

.error {
  font-size: 14px;
  color: var(--roulette-red-single);
}

.success {
  font-size: 14px;
  margin-top: auto;
  color: var(--roulette-green-single);
  text-align: center;
}