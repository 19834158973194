
.sendCoins {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sendCoins .users {
  display: flex;
  justify-content: center;
}

.sendCoins .users img {
  height: 44px;
  border-radius: 50%;
  float: left;
  transition: opacity 300ms, transform 300ms;
}

.sendCoins .users img:hover {
  cursor: pointer;
  opacity: .7;
  transform: translateY(-2px);
}

.sendCoins .users svg {
  height: 20px;
  margin: 12px 10px;
  float: left;
}

.sendCoins .desc {
  text-align: center;
  margin: 20px 0 15px;
  font-size: 14px;
  color: var(--text-color-chat);
}

.sendCoins .desc span {
  font-weight: 600;
}

.sendCoins button {
  margin-top: auto;
}

.sendCoins .input {
  background-color: var(--bg-color-2);
  width: 50%;
  margin: 0 25%;
  float: left;
  border-radius: 52px;
}

.sendCoins .input input {
  outline: 0;
  border: 0;
  background: transparent;
  color: var(--text-color-primary);
  height: 34px;
  float: left;
  width: calc(100% - 38px);
}

.sendCoins .input svg {
  height: 16px;
  margin: 9px;
  float: left;
}